import { createFileRoute, notFound } from '@tanstack/react-router'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import {
  Loader,
  NoData,
  Order,
  PayWith,
  Redirecting,
  PaymentExpired,
  PaymentSuccessful,
} from '@/components'
import { useDocumentTitle } from '@/hooks/use-document-title'
import { EmbedEvent, fetchPaymentLink } from '@/utils'
import { updateFavicon } from '@/utils/update-favicon'

export const Route = createFileRoute('/link/$id')({
  loader: async ({ params: { id }, context }) => {
    const paymentLink = await fetchPaymentLink(id)
    if (!paymentLink) return { paymentLink: null }
    context.paymentLink = paymentLink
    updateFavicon(paymentLink?.merchantFaviconUrl)

    return { paymentLink }
  },
  component: Link,
  pendingComponent: () => <Loader />,
  pendingMinMs: 0,
  onError: (error) => {
    console.error(error)
    throw notFound()
  },
})

function Link() {
  const embedRef = useRef<{ submit: () => void }>(null)
  const [isEmbedReady, setIsEmbedReady] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const { paymentLink } = Route.useLoaderData()
  useDocumentTitle(paymentLink?.merchantName)

  const handleEmbedEvent = <E extends keyof EmbedEvent>(event: E) => {
    if (event === 'optionsLoaded') {
      setIsEmbedReady(true)
    }

    if (event === 'transactionCreated') {
      setIsProcessing(true)
    }
  }

  const handleEmbedSubmit = () => {
    embedRef.current?.submit()
  }

  if (!paymentLink) {
    return <NoData />
  }

  if (paymentLink.status === 'expired') {
    return <PaymentExpired />
  }

  if (isSuccess || paymentLink.status === 'completed') {
    return <PaymentSuccessful />
  }

  if (isProcessing) {
    setTimeout(() => {
      if (paymentLink.returnUrl) {
        window.location.href = paymentLink.returnUrl
      } else {
        setIsSuccess(true)
      }
    }, 1500)

    return <Redirecting />
  }

  return (
    <>
      <Loader className={isEmbedReady ? 'hidden' : ''} />
      <div
        className={clsx(
          'gap-48 gap-y-24 md:flex',
          !isEmbedReady ? '!hidden' : ''
        )}
      >
        <Order />
        <PayWith
          embedRef={embedRef}
          handleEmbedSubmit={handleEmbedSubmit}
          handleEmbedEvent={handleEmbedEvent}
        />
      </div>
    </>
  )
}
