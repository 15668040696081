import { getMimeType } from './get-mime-type'

export const DEFAULT_FAVICON = '/gr4vy.png'
export const DEFAULT_MIME_TYPE = 'image/png'

const isValidUrl = (url?: string): url is string => !!url && URL.canParse(url)

const generateLinkElement = (): HTMLLinkElement => {
  const linkElement = document.createElement('link')
  linkElement.rel = 'icon'
  return linkElement
}

const setupFavicon = (href: string, type: string) => {
  const linkElement = generateLinkElement()
  linkElement.href = href
  linkElement.type = type
  document.head.appendChild(linkElement)
}

export const updateFavicon = (url?: string) => {
  try {
    let faviconUrl = DEFAULT_FAVICON
    let mimeType = DEFAULT_MIME_TYPE

    if (isValidUrl(url)) {
      faviconUrl = url
      mimeType = getMimeType(url)
    }

    setupFavicon(faviconUrl, mimeType)
  } catch {
    // silently fail and keep the default favicon
  }
}
