import { FormattedMessage, useIntl } from 'react-intl'
import { Heading } from '@/components/Heading'
import { formatCurrency, formatExpirationDate } from '@/utils'
import { PaymentLink } from '@/utils/types'

export type OrderSectionProps = Pick<
  PaymentLink,
  'externalIdentifier' | 'expiresAt' | 'cartItems' | 'amount' | 'currency'
>

export const OrderSection = ({
  externalIdentifier,
  expiresAt,
  cartItems,
  amount,
  currency,
}: OrderSectionProps) => {
  const intl = useIntl()
  const order = intl.formatMessage(
    { id: 'ORDER', defaultMessage: 'Order {orderId}' },
    { orderId: externalIdentifier }
  )
  const validUntil = intl.formatMessage({
    id: 'VALID_UNTIL',
    defaultMessage: 'Valid until',
  })

  return (
    <section className="flex flex-col gap-8">
      <header className="flex flex-col gap-12 lg:flex-row lg:items-center lg:justify-between">
        <Heading icon="order">{order}</Heading>
        <div className="flex flex-shrink-0 items-center gap-8">
          <p>{validUntil}</p>
          <span className="rounded-rounded bg-amber-10 p-4">
            {formatExpirationDate(expiresAt)}
          </span>
        </div>
      </header>
      <main className="border-y border-gray-50">
        <ul className="list-none border-b border-gray-50 p-none">
          {cartItems?.map((item) => (
            <li
              key={item.name}
              className="flex items-center border-gray-50 text-left [&:not(:last-child)]:border-b"
            >
              <article className="flex grow justify-between gap-16 py-8">
                <div className="flex gap-16">
                  <span className="after:content-['x']">{item.quantity}</span>
                  <span>{item.name}</span>
                </div>
                <span>
                  {formatCurrency(item.unitAmount, {
                    currency: currency,
                  })}
                </span>
              </article>
            </li>
          ))}
        </ul>
        <section className="flex min-h-40 w-full items-center justify-between border-t border-gray-50 py-12">
          <span className="text-base">
            <FormattedMessage id="TOTAL_AMOUNT" defaultMessage="Total amount" />
          </span>
          <span className="text-2xl font-medium">
            {formatCurrency(amount, { currency })}
          </span>
        </section>
      </main>
    </section>
  )
}
