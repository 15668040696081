import { useEffect, useState } from 'react'
import { tailwindConfig } from '@/utils'

const matchMedia = (query?: string) =>
  query ? window.matchMedia(query) : window.matchMedia('')

const useMediaListener = (query: string) => {
  const [matches, setMatches] = useState(matchMedia(query).matches)

  useEffect(() => {
    const media = matchMedia(query)
    setMatches(media.matches)

    const callback = (event: MediaQueryListEvent) => {
      setMatches(event.matches)
    }

    media.addEventListener('change', callback)
    return () => {
      media.removeEventListener('change', callback)
    }
  }, [query])

  return matches
}

export const useMediaQuery = (query?: string) => {
  const matches = useMediaListener(query ?? '')
  const matchesMd = useMediaListener(
    `(min-width: ${tailwindConfig.theme.screens.md})`
  )

  return {
    matches,
    matchesMd,
  }
}
