import { useParams } from '@tanstack/react-router'
import { FormattedMessage } from 'react-intl'
import circleWarning from '@/assets/images/icons/circle-warning.svg'

export const NoData = () => {
  const { id: paymentLinkId } = useParams({ from: '/link/$id' })

  return (
    <div className="my-auto space-y-12 px-48 py-72 md:p-56">
      <img src={circleWarning} className="inline-block" />
      <FormattedMessage
        tagName="h2"
        id="NO_DATA"
        defaultMessage="We could not find your payment link with id {paymentLinkId}"
        values={{ paymentLinkId }}
      />
    </div>
  )
}
