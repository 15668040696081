import { PropsWithChildren, useState } from 'react'
import { UIContext } from './UIContext'

export const UIProvider = ({ children }: PropsWithChildren) => {
  const [mobile, setMobile] = useState({ step: 1 })

  return (
    <UIContext.Provider value={{ mobile, setMobile }}>
      {children}
    </UIContext.Provider>
  )
}
