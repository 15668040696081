import clsx from 'clsx'
import { ReactNode } from 'react'

export interface ButtonProps {
  children: ReactNode
  className?: string
  onClick?: () => void
}

export const Button = ({ children, className, onClick }: ButtonProps) => {
  return (
    <button
      className={clsx(
        'gap-2 rounded-md ring-offset-background focus-visible:ring-ring h-10 py-2 inline-flex items-center justify-center whitespace-nowrap bg-[--color-merchant] px-4 text-base font-medium text-white transition-colors hover:bg-[--color-hover] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:shrink-0',
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
