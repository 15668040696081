import { useMatch } from '@tanstack/react-router'
import { PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import logo from '@/assets/images/logo.svg'
import { MerchantLogo } from '@/components'

export const Layout = ({ children }: PropsWithChildren) => {
  const { params } = useMatch({ strict: false })
  const { id } = params as { id: string }

  return (
    <div className="flex min-h-screen flex-col space-y-24 bg-gray-20 lg:p-96">
      <div className="mx-auto flex w-full max-w-5xl flex-1 flex-col bg-white text-center md:flex-grow-0">
        {id && <MerchantLogo />}
        {children}
      </div>
      <div className="pb-pay-button md:pb-none">
        <div className="relative flex items-center justify-center gap-4 pb-24 md:pb-none">
          <FormattedMessage
            id="POWERED_BY"
            defaultMessage="Powered by {brand}"
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            values={{ brand: <img src={logo} alt="Gr4vy" className="w-56" /> }}
          />
        </div>
      </div>
    </div>
  )
}
