import { useRouteContext } from '@tanstack/react-router'
import { FormattedMessage } from 'react-intl'
import circleSuccess from '@/assets/images/icons/circle-success.svg'
import { PaymentStatusLayout } from '@/components/PaymentStatusLayout'
import { formatCurrency, formatPaidOnDate } from '@/utils'

export const PaymentSuccessful = () => {
  const { paymentLink } = useRouteContext({ from: '/link/$id' })
  if (!paymentLink) return null

  const {
    amount,
    currency,
    externalIdentifier,
    merchantUrl,
    merchantName,
    status,
    updatedAt,
  } = paymentLink

  return (
    <PaymentStatusLayout>
      <div className="flex flex-col items-center gap-8">
        <img src={circleSuccess} className="inline-block w-32" />
        <h2 className="pb-none">
          <FormattedMessage
            id="PAYMENT_SUCCESSFUL"
            defaultMessage="Payment successful!"
          />
        </h2>
      </div>
      <span className="text-2xl font-medium">
        {formatCurrency(amount, {
          currency,
        })}
      </span>
      {status === 'completed' ? (
        <FormattedMessage
          id="THANKS_PURCHASE_ON"
          tagName="span"
          defaultMessage="Thanks for your purchase on {date}."
          values={{
            date: (
              <span className="font-bold">{formatPaidOnDate(updatedAt)}</span>
            ),
          }}
        />
      ) : (
        <FormattedMessage
          id="THANKS_PURCHASE"
          tagName="span"
          defaultMessage="Thanks for your purchase."
        />
      )}
      {externalIdentifier && (
        <div className="flex flex-col gap-4">
          <span className="truncate">
            <FormattedMessage
              id="ORDER"
              defaultMessage="Order {orderId}"
              values={{ orderId: externalIdentifier }}
            />
          </span>
        </div>
      )}
      {merchantUrl && merchantName && (
        <a href={merchantUrl}>
          <FormattedMessage
            id="RETURN_TO"
            tagName="span"
            defaultMessage="Return to"
          />
          <span className="font-bold"> {merchantName}</span>
        </a>
      )}
    </PaymentStatusLayout>
  )
}
