export const getMimeType = (url: string): string => {
  const types: Record<string, string> = {
    ico: 'image/x-icon',
    png: 'image/png',
    svg: 'image/svg+xml',
    gif: 'image/gif',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
  }

  const regex = /\.([^./?#]+)(?:[?#]|$)/
  const ext = regex.exec(url)?.[1]?.toLowerCase()
  return types[ext ?? ''] || 'image/x-icon'
}
