/** @type {import('tailwindcss').Config} */
import poutineTailwindPreset from '@gr4vy/poutine-tailwind-preset'

export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx,mdx}'],
  theme: {
    extend: {
      animation: {
        'spin-steps': '0.8s steps(8) spin infinite',
      },
      fontFamily: {
        sans: ['Roboto', 'sans-serif'],
      },
      maxWidth: {
        'logo-md': '19rem',
        'logo-sm': '15rem',
      },
      spacing: {
        'pay-button': '2.625rem',
      },
    },
  },
  plugins: [],
  presets: [poutineTailwindPreset],
}
