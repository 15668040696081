import clsx from 'clsx'
import { Skeleton } from '../Skeleton'

export const Loader = ({ className }: { className?: string }) => {
  return (
    <div
      className={clsx('space-y-40 px-16 py-24 md:p-32', className)}
      data-testid="loader"
    >
      <Skeleton className="h-40 w-2/5" />
      <div className="flex gap-48">
        <div className="flex-1 space-y-32">
          <div className="space-y-8">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
          <div className="space-y-8">
            <Skeleton />
            <Skeleton />
          </div>
          <div className="space-y-8">
            <Skeleton />
            <Skeleton />
          </div>
        </div>
        <div className="hidden flex-1 space-y-32 md:block">
          <Skeleton />
          <div className="space-y-12">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    </div>
  )
}
