import { EmbedInstance } from '@gr4vy/embed-react'
import { useRouteContext } from '@tanstack/react-router'
import clsx from 'clsx'
import { CSSProperties, RefObject } from 'react'
import { useIntl } from 'react-intl'
import { SecurityLockIcon } from '@/assets/images/icons/SecurityLock'
import { Button, Heading, Embed } from '@/components'
import { useMediaQuery } from '@/hooks'
import { useUIContext } from '@/providers'
import {
  getContextFromLocation,
  formatCurrency,
  EmbedEvent,
  getButtonTextColor,
  DEFAULT_BASE_COLOR,
  getLinkColor,
  getSpinnerColor,
  pick,
} from '@/utils'

const { gr4vyId, environment } = getContextFromLocation(window.location)

export interface PayWithProps {
  embedRef: RefObject<EmbedInstance>
  handleEmbedSubmit: () => void
  handleEmbedEvent: <E extends keyof EmbedEvent>(
    event: E,
    data: EmbedEvent[E]
  ) => void
}

export const PayWith = ({
  embedRef,
  handleEmbedSubmit,
  handleEmbedEvent,
}: PayWithProps) => {
  const intl = useIntl()
  const payWith = intl.formatMessage({
    id: 'PAY_WITH',
    defaultMessage: 'Pay with',
  })
  const termsAndConditions = intl.formatMessage({
    id: 'TERMS_CONDITIONS',
    defaultMessage: 'Terms and conditions',
  })
  const apply = intl.formatMessage({
    id: 'APPLY',
    defaultMessage: 'apply',
  })
  const pay = intl.formatMessage({
    id: 'PAY',
    defaultMessage: 'Pay',
  })
  const continueToPayment = intl.formatMessage({
    id: 'CONTINUE_TO_PAYMENT',
    defaultMessage: 'Continue to payment',
  })
  const { mobile, setMobile } = useUIContext()
  const { matchesMd } = useMediaQuery()

  const handleSubmit = () => {
    if (mobile.step === 1 && !matchesMd) {
      setMobile({ step: 2 })
    } else {
      handleEmbedSubmit()
    }
  }

  const { paymentLink } = useRouteContext({ from: '/link/$id' })
  if (!paymentLink) return null

  const {
    amount,
    cartItems,
    country,
    currency,
    embedToken,
    externalIdentifier,
    intent,
    merchantTermsAndConditionsUrl,
    merchantColor,
    metadata,
    merchantAccountId,
    paymentSource,
    statementDescriptor,
    shippingDetails,
    ...rest
  } = paymentLink

  const baseColor = merchantColor || DEFAULT_BASE_COLOR
  const buttonTextColor = getButtonTextColor(baseColor)
  const linkColor = getLinkColor(baseColor)
  const spinnerColor = getSpinnerColor(baseColor)

  let buyer = null
  if (rest.buyer) {
    buyer = pick(
      {
        ...rest.buyer,
        shippingDetails: shippingDetails
          ? pick(shippingDetails, [
              'firstName',
              'lastName',
              'emailAddress',
              'address',
            ])
          : null,
      },
      ['displayName', 'externalIdentifier', 'billingDetails', 'shippingDetails']
    )
  }

  return (
    <section
      className="flex flex-col gap-24 px-16 py-24 md:basis-1/2 md:p-32 md:pl-none"
      style={
        {
          '--color-merchant': baseColor,
          '--color-text': buttonTextColor.idle,
          '--color-link': linkColor,
          '--color-hover': buttonTextColor.hover,
        } as CSSProperties
      }
    >
      <div
        className={clsx(
          'flex flex-col gap-24',
          mobile.step === 1 && !matchesMd && 'hidden md:flex'
        )}
      >
        <div className="flex flex-col gap-8">
          <Heading icon="wallet">{payWith}</Heading>
          <Embed
            forwardedRef={embedRef}
            gr4vyId={gr4vyId}
            environment={environment}
            amount={amount}
            buyer={buyer}
            country={country}
            currency={currency}
            externalIdentifier={externalIdentifier}
            intent={intent}
            metadata={metadata}
            paymentSource={paymentSource}
            statementDescriptor={statementDescriptor}
            merchantAccountId={merchantAccountId}
            cartItems={cartItems}
            token={embedToken}
            onEvent={handleEmbedEvent}
            theme={{
              colors: {
                primary: linkColor,
                info: spinnerColor.primary,
                infoBackground: spinnerColor.secondary,
              },
              fonts: {
                body: 'google:Roboto',
              },
              radii: {
                container: 'rounded',
                input: 'rounded',
              },
            }}
          />
        </div>
        {merchantTermsAndConditionsUrl && (
          <section id="terms" className="flex self-center md:self-auto">
            <a
              href={merchantTermsAndConditionsUrl}
              className={clsx('text-[--color-link]', 'inline font-bold')}
            >
              {termsAndConditions}
            </a>
            <span>&nbsp;{apply}</span>
          </section>
        )}
      </div>
      <div className="fixed bottom-none left-none z-50 w-full md:static">
        <Button
          onClick={handleSubmit}
          className="h-pay-button w-full rounded-square md:h-auto md:rounded-rounded"
        >
          <div className="text-[--color-text]">
            {mobile.step === 1 && !matchesMd ? (
              continueToPayment
            ) : (
              <div className="flex items-center gap-8">
                <SecurityLockIcon />
                <span>
                  {pay}
                  &nbsp;
                  {formatCurrency(amount, {
                    currency,
                  })}
                </span>
              </div>
            )}
          </div>
        </Button>
      </div>
    </section>
  )
}
